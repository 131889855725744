var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showGebruikerView)?_c('div',{staticClass:"pfg-gebruiker"},[_c('div',{staticClass:"pfg-gebruiker_wrapper"},[_c('div',{staticClass:"pfg-gebruiker_sidebar"},_vm._l((_vm.links),function(link){return _c('router-link',{key:link.label,staticClass:"pfg-gebruiker_sidebar_link",class:{
					'pfg-gebruiker_sidebar_link--image': link.icon === 'profile-image',
				},attrs:{"to":{ name: link.to, params: { id: _vm.id } }}},[(link.icon === 'profile-image')?[_c('div',{staticClass:"pfg-gebruiker_sidebar_link_icon pfg-gebruiker_sidebar_link_image",attrs:{"data-coach-group-30":_vm.isCoachGroup30}},[_c('figure',{staticClass:"pfg-avatar_image",style:(_vm.gebruikerImage)})])]:(typeof link.icon === 'string')?[_c('pfg-icon',{class:{
							'pfg-gebruiker_sidebar_link_icon': !link.activeIcon,
							'pfg-gebruiker_sidebar_link_icon--inactive': !!link.activeIcon,
						},attrs:{"icon-id":("icon_" + (link.icon))}}),(link.activeIcon)?_c('pfg-icon',{staticClass:"pfg-gebruiker_sidebar_link_icon--active",attrs:{"icon-id":("icon_" + (link.activeIcon))}}):_vm._e()]:[_c('FontAwesomeIcon',{staticClass:"text-2xl",class:{
							'pfg-gebruiker_sidebar_link_icon': !link.activeIcon,
							'pfg-gebruiker_sidebar_link_icon--inactive': !!link.activeIcon,
						},attrs:{"icon":link.icon}}),(link.activeIcon)?_c('FontAwesomeIcon',{staticClass:"text-2xl pfg-gebruiker_sidebar_link_icon--active",attrs:{"icon":link.activeIcon}}):_vm._e()],_c('span',{staticClass:"pfg-gebruiker_sidebar_link_text"},[_vm._v(" "+_vm._s(link.label)+" ")]),(link.slug && _vm.getStatus(link.slug))?_c('span',{staticClass:"pfg-gebruiker_sidebar_link_status"},[_c('pfg-icon',{attrs:{"icon-id":("state_" + (_vm.getStatus(link.slug)))}})],1):_vm._e()],2)}),1),_c('div',{staticClass:"pfg-gebruiker_content"},[_c('router-view')],1)])]):_c('router-view')}
var staticRenderFns = []

export { render, staticRenderFns }